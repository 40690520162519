<template>
  <b-container>
    <Header />
    <div>
      <div class="donate-header pt-5 pb-5">
        Dear Beautiful Friends, <br />
        Thank you so much for your time!
      </div>
      <div class="donate">
        This is our big ask to the universe, to the community, to anyone with
        whom this message resonates.
      </div>

      <div class="donate">
        Have you ever wanted to just leave it all behind and go live in the
        woods? Us too!! We have been talking, dreaming, researching, planning,
        and preparing for years to go off grid. Our goal is to acquire land to
        create a sanctuary for ourselves and others. We will plant permaculture
        gardens and a food forest, rescue animals, practice different styles of
        natural building, create art and build intentional community.
      </div>

      <div class="donate">
        Who we are: A family consisting of partners Cally and Warren, our
        teenage son, and Aunt Kathy. Our non-human animal family members are:
        dogs Espen and Molly; cats Leo and Gertrude; bunnies Willow, Rain, and
        Chewy; chinchilla, Mage, fish Gilligan, Professor, and Skipper, as well
        as quite a few plant friends!
      </div>

      <div class="donate">
        Collectively we are gardeners, artists, students, healers, explorers,
        wizards, witches and pioneers. We each have our own unique backgrounds
        and skills which provide us a cornucopia of tools for off grid
        sustainability.
      </div>

      <div>
        <b-img
          class="donate-image my-5"
          :src="require('@/assets/images/sunflower.jpeg')"
          fluid
          alt=""
        ></b-img>
      </div>

      <div class="donate">
        What we need: We humbly ask for your help in achieving our cherished
        dream to obtain land with which we can create housing, health, and food
        security for ourselves and others. Our goal is $65,000 to fund our land
        and off grid start up supplies (see specific numbers further down)
      </div>

      <div class="donate">Why we are asking: Our need and our vision</div>

      <div class="donate">
        Through a series of seeming misfortunes between 2020 and 2021 we have
        been blessed to be closer than ever before to living our dream! We call
        this “have to manifesting” when you ask for something and the universe
        gives it to you by putting you in a situation where you have to make the
        moves!
      </div>

      <div class="donate">
        Our immediate need is a place of our own. Having suddenly and
        unexpectedly found ourselves in the position of losing our housing, we
        made the decision to sacrifice comfort and privacy to stay with a
        generous family member in order to save the money needed to make our
        move to living off grid. With our hard work and planning we have already
        acquired the needed tools and a full solar panel array and 3 movable
        buildings (including 1 "bunchilla" barn, 1 cabin for our son and 1 cabin
        for the family). We have saved for a down payment for land and have
        downsized considerably in preparation for this new lifestyle. We are
        being very frugal and try to repurpose or salvage materials before
        acquiring second-hand, buying new materials only as a last resort.
      </div>

      <div class="donate">
        Having our own space will provide us with the ability to be
        self-sufficient, creating freedom and autonomy from banks, business
        partners, bosses, and government. With land we know we can increase our
        income and decrease our dependence on the never ending cycle of bills
        that comes along with living the rat race.
      </div>

      <div>
        <b-img
          class="donate-image my-5"
          :src="require('@/assets/images/mountains.jpeg')"
          fluid
          alt=""
        ></b-img>
      </div>

      <div class="donate">
        We can then begin to build our grand vision of cultivating a sanctuary
        for all; we envision a dynamic property which will serve and bless all
        who live there or visit.
      </div>

      <div class="donate">
        Animal sanctuary. Each of us have rescued animals for years and have the
        skills and desire to continue to provide safe, forever homes for animals
        from all walks of life. All animals are persons and we wish to help them
        live their best and most natural lives possible.
      </div>

      <div class="donate">
        We promise to honor the indigenous peoples and original caretakers of
        this land. Acknowledging the sacred duty we all share to protect and
        care for our Mother Earth and one another, we plan to give part of the
        land back to ancestral peoples, plants and animals.
      </div>

      <div class="donate">
        Permaculture gardens and food forest. We believe these will be the best
        investment in the future of food security and restoring the land by
        working with nature, not against it to meet our needs. We already
        practice the principles of permaculture, applying them not only to our
        gardens, but all aspects of life, looking for win-win solutions to
        challenges. Applying a do no harm philosophy to designing our lives we
        are learning to create closed loop systems that eliminate waste.
      </div>

      <div class="donate">
        We wish to foster intentional community by providing safe community
        spaces, sharing the land with friends, family, and visitors from far and
        wide. The land will automatically become a gathering space for
        festivals, healing, music, celebrations of life and death, sharing
        knowledge and ideas, socializing and celebrating all occasions!! We have
        plans for a community library, an outdoor community kitchen, and shared
        art/creation space.
      </div>

      <div>
        <b-img
          class="donate-image my-5"
          :src="require('@/assets/images/magical.jpg')"
          fluid
          alt=""
        ></b-img>
      </div>

      <div class="donate">
        We also plan on building hiking and meditation trails, hosting entheogen
        retreats (providing guided ceremonies to aid in PTSD, depression and
        anxiety) and setting aside natural burial space with on site death doula
        services.
      </div>

      <div class="donate">
        In summary, the first part of our goal is to learn new ways of living.
        The second part is to share our journey and knowledge with others so
        they can be empowered to live free as well! This includes sharing our
        land, knowledge, skills and creating community.
      </div>

      <div class="donate">
        How we plan to do it: As soon as we have the land we will place our
        cabins there, which we will live in while we build our cob houses. The
        cabins will then become a place for visitors, woofers, and air bnb
        guests (which will contribute to our financial stability and help us
        continue to invest in the land).
      </div>

      <div>
        <b-img
          class="donate-image my-5"
          :src="require('@/assets/images/hammock-blanket.jpeg')"
          fluid
          alt=""
        ></b-img>
      </div>

      <div class="donate">
        With natural building techniques such as cob we can use materials
        sourced from our land to create sustainable, beautiful, healthy homes
        and community spaces with our bare hands, creativity and love! We wish
        to see everyone empowered to build their own, debt free homes, and want
        to lead by example.
      </div>

      <div class="donate">
        Where: The mountains in North Carolina. If you’ve ever looked into going
        off grid you'll quickly learn that it is very difficult to do so in the
        USA. Restrictions make it illegal almost everywhere (this includes
        rainwater collecting, natural building, and using sustainable energy)
        North Carolina is considered one of the best states to live off grid and
        has very few restrictions. Warren has lived in North Carolina for 13
        years and we all have roots in and share a long time dream of living in
        the beautiful Appalachian mountains, which offer an abundance of fresh
        water, wild food and medicine, good growing conditions, low cost of
        living, many like-minded people, tourism opportunities, and the peaceful
        serenity needed for our mental and physical well-being.
      </div>

      <div class="donate">
        When: We have set the intention to purchase our land by Christmas. We
        are extremely determined to make this happen, we won't give up and will
        flow with ease around setbacks as we see our vision through, no matter
        how long it takes.
      </div>

      <div>
        <b-img
          class="donate-image my-5"
          :src="require('@/assets/images/woman-mountains.jpeg')"
          fluid
          alt=""
        ></b-img>
      </div>

      <div class="donate">
        What your donations will help pay for: The most important asset, LAND
        (The most promising property we have found so far is $55,000 for over 16
        acres, which includes a spring! Paying cash and owning outright is
        ideal, but we also working to secure financing to keep all options open)
      </div>

      <div class="donate">
        <strong
          >Necessities we still need for our initial shelter: ($10,000
          total)</strong
        >
        <ul class="pt-3 order-list">
          <li>cost of transporting the cabins to our property ($3,500)</li>
          <li>Batteries ($2000)</li>
          <li>and inverter for our solar panels($900)</li>
          <li>Washer/ dryer combo ($1000)</li>
          <li>Composting toilets ($150)</li>
          <li>Hot water heaters ($300)</li>
          <li>Refrigerator ($300)</li>
          <li>
            Generator (For days that there isn’t enough sun to charge the
            batteries) ($500)
          </li>
          <li>
            Estimated $1,000 for closing costs and other expenses relating to
            our initial setup (such as a woodstove, water pump, etc)
          </li>
        </ul>
      </div>

      <div class="donate">
        With intention and love we ask, believing the way will appear. Doing our
        due diligence includes opening all doors of possibility! We believe in
        taking responsibility, and are putting our all into helping ourselves.
        We also know that no person or family is an island and acknowledge our
        current system is meant to keep us from breaking free from it. We strive
        for interdependence and mutual benefit from all situations. We believe
        in our vision and we hope you will too!
      </div>

      <div class="donate">
        We are each so grateful for all support in whatever form it may come;
        financial, but also energetic in the form of knowledge/advice, hope,
        in-person help, item donations, and sharing our page. With our sincerest
        gratitude we say thank you!!!
      </div>
    </div>

    <div
      class="sparkle-container m-3 mx-auto"
      @mouseover="showSparkles = true"
      @mouseout="showSparkles = false"
    >
      <Sparkles
        v-show="showSparkles"
        v-for="sparkle in sparkleCount"
        :key="sparkle"
        :id="sparkle"
      />

      <b-icon-venmo>
        <b-btn
          href="https://venmo.com/u/CallySmith"
          target="_blank"
          class="main-button my-5 mr-5"
          >Venmo</b-btn
        >
      </b-icon-venmo>

      <b-icon-paypal>
        <b-btn
          href="https://www.paypal.me/sirensongshop"
          target="_blank"
          class="main-button my-5"
          >Paypal</b-btn
        >
      </b-icon-paypal>
    </div>
  </b-container>
</template>

<script>
import Button from "../components/Button.vue";
import Header from "../components/Header.vue";
import Sparkles from "@/components/Sparkles";

export default {
  components: { Header, Button, Sparkles },
  data() {
    return {
      sparkleCount: 15,
      showSparkles: false,
    };
  },
};
</script>

<style scoped>
.sparkle-container {
  position: relative;
  z-index: 999 !important;
}

.btn {
  color: #3b5255;
  border: #3b5255 solid 1px;
  border-radius: unset;
  padding: 15px;
  font-size: 25px;
  letter-spacing: 2.5px;
  background-color: rgba(255, 255, 255, 0) !important;
}

.btn:hover {
  border: rgb(50, 133, 201) solid 1px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgb(50, 133, 201);
}

.donate {
  font-size: 23px;
  padding-bottom: 23px;
  max-width: 70%;
  margin: auto;
  text-align: center;
}

.order-list {
  text-align: left;
}

.donate-header {
  text-align: center;
  font-size: 30px;
  color: rgb(50, 133, 201);
}

.donate-image {
  margin-top: 5vh;
  box-shadow: 1px 1px 20px 10px #3b52558a;
  width: 500px;
}
</style>
